@value unit, text-color, content-background-color, error-color from '../../../../ring-globals.css';

.container {
  margin: 0 auto 0 calc(4 * unit);
  padding-top: calc(unit);

  color: text-color;

  background-color: content-background-color;
}

.failure-icon {
  margin-right: calc(unit);
}

.failed-build {
  color: error-color;
}
